
import { useEffect, useState } from "react";
import apiCall, { URLS } from "../../webservice/ApiCallService";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function AskStrategyPopUp({ show, handleClose,deployChange })
{
  const [isConfirm,setIsConfirm] = useState(false)
  const [selectItem,setSelectItem] = useState([])
  const [deployList, setDeployList] = useState([]);
  const token = useSelector((store) => store.user.value.token);

  useEffect(()=>{
    setIsConfirm(false)
  },[selectItem])

  useEffect(()=>{
    setIsConfirm(false)
    setSelectItem([])
  },[show])

  useEffect(() => {
    loadDeployStrategy();
  }, []);

  const loadDeployStrategy = async () => {
    const depStrList = await apiCall.getCallWithToken(
      URLS.LIST_DEPLOY_STRATEGY,
      token
    );
    setDeployList(depStrList.data);
  };

  const handleConfirm = (e)=>{
    if(selectItem.length==0)
    {
      toast.error("Please Select Any Strategy !")
    }else{
      setIsConfirm(e.target.checked)
    }
  }
  
  return <div className={`modal ${show ? "display-block" : "display-none"} profiler`}>
    <div className="modal-content">
      <h3 id="HeadText" className="text-center">Deployed Strategies</h3>
      <button className="btn-sm btn-success" 
      onClick={()=>setSelectItem(deployList.map(ob=>ob.strategy))}>Select All</button>
      <span className="close" onClick={handleClose}>
        &times;
      </span>
      <div className="container">
        <table className="table">
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Name</th>
              <th>Instrument</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {deployList.map((ob,index) => <DeployListItem obj={ob} token={token} index={index+1} selectItem={selectItem} handleItem={(id)=>{
              if(selectItem.includes(id))
                setSelectItem(selectItem.filter(i=>i!=id))
              else
                setSelectItem([...selectItem,id])  
            }}/>)}
          </tbody>
        </table> 
        <div className="row mt-3">
          <div className="col-xl-8 col-lg-8">
            <input id="confirm" type="checkbox" checked={isConfirm} onChange={handleConfirm}/> &nbsp; <label htmlFor="confirm">Confirm before deployment</label>
            <hr/>
            <b className="text-danger">
              Deploy Strategy Takes time (Approx 15Sec) after ON.
            </b>
          </div>
          <div className="col-xl-4 col-lg-4">
            <button onClick={()=>{
              deployChange(selectItem);
              handleClose();
            }} className="btn btn-danger" disabled={!isConfirm}>Deploy</button>
          </div>
        </div>       
      </div>       
    </div>
  </div>
}

const DeployListItem = ({ obj , token,index,selectItem,handleItem}) => 
  {
    const [strategy, setStrategy] = useState(undefined);
  
    useEffect(()=>{
      loadStrategy()
    },[])
  
    const loadStrategy = async ()=>
    {
      const url = URLS.GET_ACTIVE_STRATEGY + obj.strategy;
      const res = await apiCall.getCallWithToken(url,token);
      console.log(res)
      if(res.status)
      {
        setStrategy(res.data)
      }
    }
  
    return strategy==undefined ? <></> : <tr onClick={()=>handleItem(strategy.id)} className={selectItem.includes(strategy.id)?"table-primary":""}>
        <td>{index}</td>
        <td>{strategy?.name}</td>
        <td>{JSON.parse(strategy.instrument).name}</td>  
        <td>{strategy.type} , {strategy.order_type}</td> 
     </tr>
  };
  