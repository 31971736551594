import { useEffect, useState } from "react";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ClientDeployStrategyList() {
  const navigate = useNavigate();
  const [deployList, setDeployList] = useState([]);
  const token = useSelector((store) => store.user.value.token);

  useEffect(() => {
    loadDeployStrategy();
  }, []);

  const removeFromList = (id)=>{
    setDeployList(deployList.filter(ob=>ob._id!=id))
  }

  const loadDeployStrategy = async () => {
    const depStrList = await apiCall.getCallWithToken(
      URLS.LIST_DEPLOY_STRATEGY,
      token
    );
    setDeployList(depStrList.data);
  };

  return (
    <>
      <div className="container-fluid pt-3">
        <h3 id="HeadText">Deployed Strategies</h3>

        <div className="row mt-3">
          {deployList.map((ob) => (
            <DeployListItem obj={ob} token={token} removeFromList={removeFromList}/>
          ))}
          {/* {strategyList.map((ob,index)=>)} */}
        </div>
      </div>
    </>
  );
}

const DeployListItem = ({ obj , token,removeFromList}) => 
{
  const [strategy, setStrategy] = useState(undefined);

  useEffect(()=>{
    loadStrategy()
  },[])

  const loadStrategy = async ()=>
  {
    const url = URLS.GET_ACTIVE_STRATEGY + obj.strategy;
    const res = await apiCall.getCallWithToken(url,token);
    console.log(res)
    if(res.status)
    {
      setStrategy(res.data)
    }
  }

  const unDepoly = ()=>{
    //alert(obj._id)
    const url = URLS.UNDEPLOY_STRATEGY+obj._id;
    apiCall.patchCallWithToken(url,token).then(res=>{
      if(res.status)
      {
        removeFromList(obj._id)
      }
    })
  }

  return (
    <>
      <div className="col-xl-3 col-lg-3 text-center">
        {strategy != undefined ? (
          <>
            <h3 className="text-center">{strategy?.name} </h3>
            <b className="text-danger">
              {JSON.parse(strategy.instrument).name}
            </b>
            <br />
            <img src="/images/strategy.jpeg" className="img-thumbnail" />
            <br />
            {strategy.legs.map((l) => (
              <>
                <b className={l.type == "BUY" ? "text-success" : "text-danger"}>
                  {l.type}
                </b>{" "}
                &nbsp;&nbsp;
              </>
            ))}
            <br />
            <br />

            <button onClick={unDepoly} className="btn btn-outline-danger">Remove Strategy</button>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
