import { useEffect, useState } from "react";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ClientStrategyList() 
{
  const navigate = useNavigate();
  const [strategyList, setStrategyList] = useState([]);
  const [deployList,setDeployList] = useState([]);
  const token = useSelector((store) => store.user.value.token);
  
  
  useEffect(() => {
    loadDepStrategy();
    loadStrategy();
  }, []);
  const loadDepStrategy = async () => 
  {    
    const depStrList = await apiCall.getCallWithToken(URLS.LIST_DEPLOY_STRATEGY,token);
    setDeployList(depStrList.data.map(ob=>ob.strategy*1));
  };
  const loadStrategy = async () => 
    {    
      const resp = await apiCall.getCallWithToken(URLS.LIST_ACTIVE_STRATEGY, token);
      //console.log(resp)
      setStrategyList(resp.data);
    };
  
  const deployStrategy = (id)=>
  {
    const url = URLS.DEPLOY_STRATEGY+id;
    apiCall.postCallWithToken(url,token).then(res=>
    {
      if(res.status)
      {
        setDeployList([...deployList,id])
      }
    })
  }
  return (
    <>
      <div className="container-fluid pt-3">
        <h3 id="HeadText">
          List Strategies
        </h3>

        <div className="row mt-3">
          {strategyList.map((ob,index)=><div key={`str${index}`} className="col-xl-3 col-lg-3 text-center">
            <h3 className="text-center">{ob.name} </h3>
            <b className="text-danger">{JSON.parse(ob.instrument).name}</b><br/>
            <img src="/images/strategy.jpeg" className="img-thumbnail"/>
            <br/> 
            {ob.legs.map(l=><>
              <b className={l.type=="BUY"?"text-success":"text-danger"}>{l.type}</b> &nbsp;&nbsp;
            </>)}
            <br/><br/>

            {deployList.includes(ob.id)?<button className="btn btn-outline-danger" onClick={()=>navigate("/client/mystrategy")}>
              Remove Strategy
            </button>:<button onClick={()=>deployStrategy(ob.id)} className="btn btn-outline-primary">Add Strategy</button>}
            
          </div>)}
        </div>
      </div>
    </>
  );
}
