import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import "./custom-admin.css";
import { useSelector } from "react-redux";
import apiCall, { URLS } from "../../webservice/ApiCallService";
import { useEffect, useRef, useState } from "react";

import toast from "react-hot-toast";

export default function Clients() {
  const loginUserToken = useSelector((store) => store.user.value.token);
  const ROLE = "client" ;
  const askPopref = useRef();
  const nameRef = useRef();
  const mobRef = useRef();
  const emailRef = useRef();
  const passRef = useRef();
  const checkbox=useRef();
  const labelbox=useRef();
  const [userListStatus, setUserListStatus] = useState(1);
  const [isRegRun, setIsRegRun] = useState(false);
  const [msg, setMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const [clist, setCList] = useState([]);
  useEffect(() => {
    loadClients();
  }, [userListStatus]);

  const loadClients = async () => {
    const listURL = URLS.LIST_USERS + `?role=${ROLE}&status=${userListStatus}`;
    const resp = await apiCall.getCallWithToken(listURL, loginUserToken);
    setCList(resp.data.map((ob,ind)=>{return {...ob,index:ind+1}}));
  };

  const changeUserStatus = (userId) => 
  {
    apiCall.patchCallWithToken(URLS.STATUS_CHANGE + `/${userId}`, loginUserToken);
    setCList(clist.filter((ob) => ob.id !== userId));
  };
  const blockUser = (userId) => {
    apiCall.patchCallWithToken(URLS.CHANGE_BLOCK_STATUS + `/${userId}`, loginUserToken);
    setCList(clist.filter((ob) => ob.id !== userId));
  };

  //custom toast Styling
  const toastStyle = {
    popstyle: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      padding: "16px",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      maxWidth: "300px",
      margin: "0 auto",
    },
  };
  const askPop = (event, ob) => 
  {
    const buttonId = event.currentTarget.getAttribute('data-id');
    toast.custom((t) => (
      <div style={toastStyle.popstyle}>
        {buttonId === "statususer" ? (
          userListStatus ? (
            <p>Do you want to Deactive {ob.name}</p>
          ) : (
            <p>Do you want to Active {ob.name} </p>
          )
        ) : (
          <p>Do you want to Block {ob.name}</p>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <button
            onClick={() => {
              {
                buttonId === "statususer"
                  ? changeUserStatus(ob.id)
                  : blockUser(ob.id);
              }
              toast.dismiss(t.id);
              toast.success(
                buttonId === "statususer"
                  ? ob.active_status
                    ? `${ob.name} is Deactive`
                    : `${ob.name} is Active`
                  : `${ob.name} is Blocked`,{
                    duration:500
                  }
              );
            }}
            style={{
              backgroundColor: "#4caf50",
              color: "#fff",
              border: "none",
              padding: "8px 16px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Yes
          </button>
          <button
            onClick={() => {
              toast.dismiss(t.id)
              toast.error("cancelled",{duration:500})
            }}
            style={{
              backgroundColor: "#f44336",
              color: "#fff",
              border: "none",
              padding: "8px 16px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            No
          </button>
        </div>
      </div>
    ));
  };
  const tog=()=>{
      if(checkbox.current.checked){
        setUserListStatus(1)
        labelbox.current.style.backgroundColor="green";
        labelbox.current.parentElement.style.color="green";
      }
      else{
        setUserListStatus(0)
        labelbox.current.style.backgroundColor="#a32626"
        labelbox.current.parentElement.style.color="#a32626";
      }
  }
  const columns = [
    { field: "index", headerName: "S. No.", width: 70 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "mobile", headerName: "Mobile", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "active_status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.value === true
                ? "green"
                : params.value === false
                ? "gray"
                : "red",
          }}
        >
          {params.value === true ? "active" : "inactive"}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => {
        const { id, active_status } = params.row;
        return (
          <>
            {active_status !== 'users' && (
              <Button
                data-id="statususer"
                variant="contained"
                color={active_status === true ? 'secondary' : 'primary'}
                startIcon={active_status === true ? <CloseIcon /> : <CheckIcon />}
                onClick={(e) =>askPop(e,params.row)}
                style={{ marginRight: '10px' }}
              >
                {active_status === true ? 'Deactivate' : 'Activate'}
              </Button>
            )}
            <Button
              data-id="blockuser"
              variant="contained"
              color="error"
              startIcon={<BlockIcon />}
              onClick={(e) => askPop(e, params.row)}
            >
              Block
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <>
      <div className="container-fluid pt-3">
        <h3 id="HeadText">CLIENTS</h3>
        <hr />
        <div className="col-lg-6 col-md-6 p-2">
          <div>
            <div className="toggleswitchUser">
           
            <input type="checkbox" className="checkbox" id="checkbox" onChange={tog} ref={checkbox} checked={userListStatus}/>
            <label htmlFor="checkbox" ref={labelbox} className="checkbox-label">
              <span className="ball"></span>
            </label>
            <b>{userListStatus?"Active":"Deactive"}</b>
            </div>
          </div>
        </div>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid rows={clist} columns={columns} pageSize={5} />
        </div>  
      </div>
    </>
  );
}
