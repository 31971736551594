import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import apiCall, { URLS } from "../../webservice/ApiCallService";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import AskStrategyPopUp from "./AskStrategyPopUp";

export default function BrokerTerminal({ brokers, setBrokers }) {
  const [showPop, setShowPop] = useState(false);
  const handleOpenPop = () => setShowPop(true);
  const handleClosePop = () => setShowPop(false);

  const onCSS = {
    backgroundColor: "green",
    color: "green",
  };
  const offCSS = {
    backgroundColor: "#a32626",
    color: "#a32626",
  };
  const loginUser = useSelector((store) => store.user.value);

  const [index, setIndex] = useState(0);

  const next = () => {
    if (index == brokers.length - 1) setIndex(0);
    else setIndex(index + 1);
  };
  const previous = () => {
    if (index == 0) setIndex(brokers.length - 1);
    else setIndex(index - 1);
  };

  const handleTerminal = (event) => {
    const status = event.target.checked;

    if (brokers[index].isdeployon) {
      toast.error("Deployment is ON !");
    } else {
      const bid = brokers[index].id;
      const url = URLS.CHANGE_TERMINAL_STATUS + bid;

      toast.promise(apiCall.getCallWithToken(url, loginUser.token), {
        loading: "Terminal Process Running ...",
        success: (result) => {
          if (result.status) {
            setBrokers(
              brokers.map((b) =>
                b.id == bid ? { ...b, isterminalon: !b.isterminalon } : b
              )
            );
            toast.success(`${brokers[index].name} Terminal Status Changed.`);
          } else toast.error(result.msg);
        },
      });
    }
  };

  const deployChange = (selectItem) => {
    const bid = brokers[index].id;
    const url = URLS.CHANGE_DEPLOY_STATUS + bid;

    toast.promise(
      apiCall.postCallWithToken(url, loginUser.token, {
        strategy: selectItem,
      }),
      {
        loading: "Deploy Process Running ...",
        success: (result) => {
          console.log(result);
          if (result.status) {
            setBrokers(
              brokers.map((b) =>
                b.id == bid ? { ...b, isdeployon: !b.isdeployon } : b
              )
            );
            toast.success(`${brokers[index].name} Deploy Status Changed.`);
          } else toast.error(result.msg);
        },
      }
    );
  };
  const handleDeploy = (event) => {
    const status = event.target.checked;

    if (status) {
      if (brokers[index].isterminalon) handleOpenPop();
      else toast.error("Turn On Terminal First !");
    } else {
      const bid = brokers[index].id;
      const url = URLS.CHANGE_DEPLOY_STATUS + bid;
      apiCall.getCallWithToken(url, loginUser.token).then((result) => {
        if (result.status) {
          setBrokers(
            brokers.map((b) =>
              b.id == bid ? { ...b, isdeployon: !b.isdeployon } : b
            )
          );
          toast.success(`${brokers[index].name} Deploy Status Changed.`);
        } else toast.error(result.msg);
      });
    }
  };
  return (
    <>
      <AskStrategyPopUp
        show={showPop}
        handleClose={handleClosePop}
        deployChange={deployChange}
      />
      <div className="row">
        <div className="col-xl-12 col-md-12 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <h4 className="text-center text-danger">{brokers[index].name}</h4>
              <div className="row mt-3">
                <div className="col-xl-1 col-lg-1">
                  <FaLessThan onClick={previous} className="text-danger" />
                </div>
                <div className="col-xl-3 col-lg-3">
                  Strategy Output <br />
                  <i className="fa-solid fa-indian-rupee-sign"></i> 0.00
                </div>
                <div className="col-xl-4 col-lg-4">
                  <b className="text-danger">Terminal</b>
                  <div className="toggleswitchUser">
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="terminalStatusCheck"
                      onChange={handleTerminal}
                      checked={brokers[index].isterminalon}
                    />
                    <label
                      htmlFor="terminalStatusCheck"
                      className="checkbox-label"
                      style={brokers[index].isterminalon ? onCSS : offCSS}
                    >
                      <span className="ball"></span>
                    </label>
                    <b className="txt-ttl">
                      {brokers[index].isterminalon ? "ON" : "OFF"}
                    </b>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3">
                  <b className="text-danger">Deploy Strategy</b>
                  <div className="toggleswitchUser">
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="deployStatusCheck"
                      onChange={handleDeploy}
                      checked={brokers[index].isdeployon}
                    />
                    <label
                      htmlFor="deployStatusCheck"
                      className="checkbox-label"
                      style={brokers[index].isdeployon ? onCSS : offCSS}
                    >
                      <span className="ball"></span>
                    </label>
                    <b className="txt-ttl">
                      {brokers[index].isdeployon ? "ON" : "OFF"}
                    </b>
                  </div>
                </div>
                <div className="col-xl-1 col-lg-1">
                  <FaGreaterThan onClick={next} className="text-danger" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
